<p-toast position='center'></p-toast>

<div class="login-panel">
	<div class="p-grid p-nogutter p-justify-center p-align-center" style="height:100%">
		<div class="p-xl-3 p-lg-6 p-md-8 p-sm-10">
			<div class="card card-primary card-outline">
				<div class="card-header ">
					<div class="p-grid p-justify-center p-align-center">
						<div class="p-xl-6 p-lg-6 p-md-8 p-sm-10">
							<img src="assets/img/logo.png" class="login-logo">
						</div>
					</div>
					<div class="p-grid p-justify-center p-align-center">
						<div class="p-text">
							
							<!-- Versión 24.06.2024 -->
							{{this.angularProjectVersion}}
						
						</div>
					</div>
				</div>
				<div class="card-body" *ngIf="emp_name">
					<div class="p-grid text-center">
						<div class="p-col-12">
							<div class="login-box-msg mt5">{{empresa_name}}</div>
						</div>
					</div>
					<div class="p-grid text-center">
						<div class="p-col-12 p0 m0">
							<p class="text-muted p0 m0 mb10">{{emp_name}}</p>
						</div>
						<div class="p-col-12">
							<div class="ui-inputgroup">
								<input [(ngModel)]="password" type="password" size="30" pInputText
									placeholder="Contraseña" class="form-control voraz" />
							</div>
						</div>
					</div>
					<!--Code for REcaptcha -->
					<div class="p-grid" style="display: block">
						<div class="p-col-12">
							<div class="ui-inputgroup">
								<form [formGroup]="aFormGroup">
									<!--portal COSOFT -->
									<ngx-recaptcha2 #captchaElem siteKey="6LeR1cAgAAAAAFmAWjzoApoAGQZ4w4lcCeNzMnzW"
										(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
										[type]="type" formControlName="recaptcha">
										
										<!--portal 347-->
										<!--<ngx-recaptcha2 #captchaElem siteKey="6LcboCUiAAAAAAJxNiapaLdr_SWjM82AiBSHqQyC"
										(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
										[type]="type" formControlName="recaptcha">-->
									</ngx-recaptcha2>
								</form>
							</div>
						</div>
					</div>
						<!--Code for REcaptcha End-->
					<div class="p-grid mt10">
						<div class="p-col-7">
							<label class="left"><input type="checkbox" id="remember"><strong>Recordar
									acceso</strong></label>
						</div>
						<div class="p-col-5 right mtm10">
							<p-button
								class="ui-button-raised ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only right"
								(click)="login()" label="Acceder"></p-button>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12 mt20">
							<a href="" class="text-center left" (click)="view_recordar();">Olvidé mi contraseña</a>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12 mt20">
							<span class="text-muted" (click)="rgpd()"><i class="fas fa-info-circle"></i> Información
								RGPD y LSSI
							</span>
						</div>
					</div>
				</div>
				<div class="card-body" *ngIf="!emp_name">
					<div class="p-grid p-justify-center p-align-center">
						<div class="login-box-msg">Portal del Empleado</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="ui-inputgroup">
								<input [(ngModel)]="code" type="text" size="30" pInputText
									placeholder="Nombre de usuario" class="form-control voraz" />
							</div>
						</div>
						<div class="p-col-12"  *ngIf="!resetPassword">
							<div class="ui-inputgroup">
								<input [(ngModel)]="password" type="password" size="30" pInputText
									placeholder="Contraseña" class="form-control voraz" />
							</div>
						</div>
				
					</div>
					<!--Code for REcaptcha -->
					<div class="p-grid" style="display: block"  *ngIf="!resetPassword">
						<div class="p-col-12">
							<div class="ui-inputgroup">
								<form [formGroup]="aFormGroup">
									<!--Portal COSOFT-->
									<ngx-recaptcha2 #captchaElem siteKey="6LeR1cAgAAAAAFmAWjzoApoAGQZ4w4lcCeNzMnzW"
										(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
										[type]="type" formControlName="recaptcha">
										<!--POrtal 347-->
										<!--<ngx-recaptcha2 #captchaElem siteKey="6LcboCUiAAAAAAJxNiapaLdr_SWjM82AiBSHqQyC"
										(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
										[type]="type" formControlName="recaptcha">-->
									</ngx-recaptcha2>
								</form>
							</div>
						</div>
					</div>
						<!--Code for REcaptcha End-->
					<div class="p-grid mt10"  >
						<div class="p-col-7" *ngIf="!resetPassword">
							<label class="left"><input type="checkbox" id="remember"><strong> Recordar
									acceso</strong></label>
						</div>
						<div class="p-col-5" *ngIf="!resetPassword">
							<p-button
								class="ui-button-raised ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only right"
								(click)="login()" label="Acceder"></p-button>
						</div>
						<div style="width:100%; height:100%; border: 0px solid" *ngIf="resetPassword">
							<p-button 
								class="ui-button-raised ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only "
								(click)="view_recordar()" label="Pulse aqui para crear nueva contraseña"></p-button>
						</div>
					</div>
					<div class="p-grid"  *ngIf="!resetPassword">
						<div class="p-col-12 mt20">
							<p href="" class="text-center left" style="color:blue;cursor: pointer;" (click)="resetPassword=true">Olvidé mi contraseña</p>
						</div>
					</div>
					<div class="p-grid"  *ngIf="!resetPassword">
						<div class="p-col-12 mt20">
							<span class="text-muted" (click)="rgpd()"><i class="fas fa-info-circle"></i> Información
								RGPD y LSSI
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--<p class="text-muted" (click)="rgpd()"><i class="fas fa-info-circle"></i> Información RGPD y LSSI</p>-->

<p-dialog header="Política de protección de datos" [(visible)]="display" [style]="{'width':'70%'}">
	En cumplimiento de la LSSI, se informa al usuario que este sitio web hace uso de Cookies. Las utilizadas en esta
	extranet, son las llamadas cookies técnicas o de sesión y tienen como única finalidad identificar al usuario de la
	web miestras mantiene abierta su sesión en la aplicación. La introducción de usuario, contraseña e ingreso en la
	aplicación se tomará como consentimiento explicíto al uso de esta tecnología.
	<br />
	Los documentos digitalizados y la información adjunta enviados, visualizados o recibidos por esta extranet, tienen
	como única finalidad la prestación de los servicios contratados por el cliente. Los derechos de acceso,
	rectificación, cancelación y oposición, de conformidad con lo establecido en la Ley 3/2018 y el Reglamento (UE)
	2016/679, pueden ejercerse ante Torquemada Asesores S.L como responsable del fichero. Los derechos mencionados los
	puede ejercer a través de los siguientes medios: toraser@toraser.com, Torquemada Asesores S.L con domicilio en C/
	Santo Tomás, 6, Segovia, España
	y/o en el teléfono 921.922.000.
</p-dialog>
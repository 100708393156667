import { Component, OnInit } from '@angular/core';
import { empty, Subscription, timer } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { formatDate } from '@angular/common';
import { CBLService } from '../servicios/cbl.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { VisitorsService } from '../servicios/visitors.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmpleadoService } from '../servicios/empleado.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-fichaje',
  templateUrl: './fichaje.component.html',
  styleUrls: ['./fichaje.component.scss'],
})
export class FichajeComponent implements OnInit {
  public cabecera: boolean = false;
  hours: Subscription;
  crono: Date;
  readOnly: boolean = false;
  inout: boolean = true;
  total: string = "00:00";
  displayAsunto: boolean;
  fichajes: any[] = [];
  leftoutDays:number;
  cols: any[];
  totalWoredTimes: any[] = [];
  clientProjectEmployees: any[] = [];
  clientProjectEmployeeAll: any;

  ipaddress: string = '';
  city: string = '';
  country: string = '';
  province: string = '';
  location: string = '';
  myForm: FormGroup;
  updateEntrada = null;
  updateSalida = null;
  missLoginTimePopup: boolean;
  type: string;
  idHorario: any;
  show: boolean=true;
  showalert: boolean;
  companyName: any;
  fechaAlta: any;
  employees: any[];
  totalWoredTime: any;
  idCliente: any;
  ClienteName: any;
  projectemployee : any[] = [];



  listidClient_project_employee_horario: any = [];
  listidClient_project_employee: any = [];
  listidClientProjectEmployee: any = [];
  listidClient_project: any = [];
  listidEmpleado: any = [];
  listidhorario: any = [];
  listWorkDesc: any = [];
  listWorkDesc1:{}= {};
  listHour: any = [];
  listMinute: any = [];
  listSecond: any = [];


  readonly: boolean = false;
  
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private cbl: CBLService, private vist: VisitorsService, private httpClient: HttpClient, private fb: FormBuilder,private emleadoService:EmpleadoService,private messageService: MessageService) {
    if ('data' in this.config)
      this.readOnly = this.config.data.readOnly;
    else
      this.readOnly = false;
    this.hours = timer(0, 1000).subscribe((r) => {
      this.crono = new Date();
    });


    //sthis.projectemployee[0].hour = '';
    // this.projectemployee.minute = '';
    // this.projectemployee.idClient_project_employee = '';
    // this.projectemployee.work_desc = '';








    this.cbl.hideLeftemployeeObservable.subscribe((data:any)=>{
      let{show,showalert,leftoutDays}=data;
      this.show=show;
      this.leftoutDays=leftoutDays;
      this.showalert=showalert;

     // this.employeeSubscriptionCheck(fechaVencimiento,baja);
      
      
    });

    this.cbl.subsubEmployeeObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.companyName = data[0].Cliente;
  });




  let url2 = 'empleado?'
  + "fields=idEmpleado,idCliente,date_format(fechaAlta,'%25d/%25m/%25Y') as fechaAlta"
  + "&where=idEmpleado=" + this.cbl.id_empleado + " "
  + '&order=idEmpleado';
  

this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then((r) => {
this.employees = r['resp'];
  if (this.employees.length > 0) {
   
    this.fechaAlta = r['resp'][0].fechaAlta;
    console.log("rokopooooooooooo",this.fechaAlta );
    this.idCliente = r['resp'][0].idCliente;


      let url3 = 'clientes?'
          + "fields=idCliente,Cliente"
          + "&where=idCliente=" + this.idCliente + " "
          + '&order=idCliente';
          

        this.httpClient.get(this.cbl.urlBaseDL + url3).toPromise().then((r) => {
        this.employees = r['resp'];
          if (this.employees.length > 0) {
          
           
           
            this.ClienteName = r['resp'][0].Cliente;
            console.log("sokoooop[",this.ClienteName );


            
          }
        });



  }
});

//console.log("rokop",this.idCliente );






  }

  ngOnInit(): void {
    this.vist.getIpAddress().subscribe(res => {
      this.ipaddress = res['ip'];
      console.log(this.ipaddress + 'ip ');
      this.vist.getGEOLocation(this.ipaddress).subscribe(res => {
        console.log(res);
        this.city = res['city'];
        this.country = res['country_name'];
        this.province = res['state_prov'];
        this.location = this.city + ', ' + this.province + ', ' + this.country;
      })
    })
    this.cols = [
      { field: 'entrada', header: 'Entrada' },
      { field: 'salida', header: 'Salida' },
      { field: 'saldo', header: 'Saldo' },
    ];

    this.loadHorarios();
    this.formInit();
    this.loadEmployee();
    this.clientProjectEmployee();
    this.clientProjectEmployeeHorario();
    this.clientProjectEmpHorario();
    

    this.hours = timer(0, 1000).subscribe((r) => {
     this.WorkedToday();
      });

   


  }

  ngOnDestroy() {
    this.hours.unsubscribe();
  }

  horaToMins_old(hora) {
    return parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
  }
  //02:04:33
  horaToMins(hora) {
    return parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
  }
  horaToMinsToSec(hora) {
    return parseInt(hora.substr(0, 2)) * 3600 + parseInt(hora.substr(3, 2))*60+ parseInt(hora.substr(6, 2));
  }


  minsToHora_old(m) {
    return (String(Math.floor(m / 60))).padStart(2, "0") + ":" + (String(m % 60)).padStart(2, "0");
  }

  minsToHora(m) {
    return (String(Math.floor(m / 60))).padStart(2, "0") + ":" + (String(m % 60)).padStart(2, "0");
  }

  secToHora(m) {
    return (String(Math.floor(m / 3600))).padStart(2, "0") + ":" + String(Math.floor(m % 3600 / 60)).padStart(2, "0")+ ":" + (String(m %  3600 % 60)).padStart(2, "0");
  }


  ficharEntrada_old(): void {
    let fec = formatDate(Date.now(), 'yyyy-MM-dd H:mm', 'en-US');
    this.inout = false;
    let fichaje = { idEmpleado: this.cbl.id_empleado, inicio: fec, localizacion: this.location };
    console.log('dt', fichaje);
    this.cbl.nuevofichaje(fichaje).then((res) => {
      console.log(res);

      console.log("time",fec);
      console.log("time",fec.length - 5);
      console.log("time",fec.substr(fec.length - 5, 5));
      this.fichajes.push({ entrada: fec.substr(fec.length - 5, 5), idHorario: res['idHorario'], localizacion: res['localizacion'] });
    });
  }

  ficharSalida_old(): void {
    let fec = formatDate(Date.now(), 'yyyy-MM-dd H:mm', 'en-US');
    console.log(fec);
    let ult = this.fichajes[this.fichajes.length - 1];
    ult['salida'] = fec.substr(fec.length - 5, 5);
    let m1 = ult['entrada'];
    let m2 = ult['salida'];
    m1 = this.horaToMins(m1);
    m2 = this.horaToMins(m2);
    this.inout = true;
    let d = m2 - m1;
    ult['saldo'] = this.minsToHora(d);
    this.total = this.minsToHora(this.horaToMins(this.total) + d);
    let fichaje = { fin: fec };
    this.cbl.updatefichaje(ult['idHorario'], fichaje).then((res) => {
      console.log(res);
    });
  }

  ficharEntrada(): void {
    let fec = formatDate(Date.now(), 'yyyy-MM-dd H:mm:ss', 'en-US');
    this.inout = false;
    let fichaje = { idEmpleado: this.cbl.id_empleado, inicio: fec, localizacion: this.location };
    console.log('dt', fichaje);
    this.cbl.nuevofichaje(fichaje).then((res) => {
      console.log(res);

      console.log("en time",fec);
      console.log("en  time",fec.length - 8);
      console.log("en time",fec.substr(fec.length - 8, 8));

      // this.fichajes.push({ entrada: fec.substr(fec.length - 5, 5), idHorario: res['idHorario'], localizacion: res['localizacion'] });

      this.fichajes.push({ entrada: fec.substr(fec.length - 8, 8), idHorario: res['idHorario'], localizacion: res['localizacion'] });
    });
  }

  ficharSalida(): void {
    let fec = formatDate(Date.now(), 'yyyy-MM-dd H:mm:ss', 'en-US');
    console.log(fec);
    let ult = this.fichajes[this.fichajes.length - 1];
    console.log("sa time",fec);
    console.log("sa  time",fec.length - 8);
    console.log("sa time",fec.substr(fec.length - 8, 8));


   // ult['salida'] = fec.substr(fec.length - 5, 5);
   ult['salida'] = fec.substr(fec.length - 8, 8);
    let m1 = ult['entrada'];
    let m2 = ult['salida'];
    // m1 = this.horaToMins(m1);
    // m2 = this.horaToMins(m2);
    m1 = this.horaToMinsToSec(m1);
    m2 = this.horaToMinsToSec(m2);
    this.inout = true;
    let d = m2 - m1;
    //ult['saldo'] = this.minsToHora(d);
    ult['saldo'] = this.secToHora(d);
    //this.total = this.minsToHora(this.horaToMins(this.total) + d);
    this.total = this.secToHora(this.horaToMinsToSec(this.total) + d); 
    let fichaje = { fin: fec };
    this.cbl.updatefichaje(ult['idHorario'], fichaje).then((res) => {
      console.log(res);
    });
  }









   pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
 

numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  compareTime(saldo,Index,idHorario,x,count) {
    //alert(saldo+"-----"+Index+"-----"+idHorario+"-----"+x+"-----"+count);


       var as = saldo.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var saldoInSec = (+as[0]) * 60 * 60 + (+as[1]) * 60 + (+as[2]); 
      console.log("saldo in sec",saldoInSec);

     // var mainIndex=idHorario+"_"+x;

    var mainHour:number=0;
    var mainMinute:number=0;
    var mainSecond:number=0;

    for(var t=0;t<count;t++)
    {
      var mainIndex=idHorario+"_"+t;

      var hour=this.listHour[mainIndex];
      var hour = this.pad(hour, 2,'');
   
      var minute=this.listMinute[mainIndex];
      var minute = this.pad(minute, 2,'');
   
      var second=this.listSecond[mainIndex];
      var second = this.pad(second, 2,'');
      
   
      if(hour=='' || hour=='undefined')
      {
       hour=0;
      }
   
      if(minute=='' || minute=='undefined')
      {
       minute=0;
      }
   
      if(second==''|| second=='undefined')
      {
       second=0; 
      }

      console.log("mainHour-----------"+mainHour+"-----"+hour);
      console.log("mainMinute-----------"+mainMinute+"-----"+minute);
      console.log("mainSecond-----------"+mainSecond+"-----"+second);

      mainHour=mainHour+parseInt(hour);
      mainMinute=mainMinute+parseInt(minute);
      mainSecond=mainSecond+parseInt(second); 



    }

    
    //console.log(this.listHour.length);
    //console.log("bbbbbbbbbbbbbbbbbbbb",this.listHour);


   //var itemTime=hour+":"+minute+":"+second;

   var itemTime=mainHour+":"+mainMinute+":"+mainSecond;

   //alert(itemTime);

   //alert(itemTime);

    var at = itemTime.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var timeInSec = (+at[0]) * 60 * 60 + (+at[1]) * 60 + (+at[2]); 
    console.log("time in sec",timeInSec);


    //alert(timeInSec+" > "+saldoInSec);

      

   console.log(timeInSec+" > "+saldoInSec); 

   if(timeInSec>saldoInSec)
   {

    //alert("ssss");
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tiempo indicado es mayor al tiempo transcurrido entre la entrada y la salida.' });
    this.listHour[Index]=0;
    this.listMinute[Index]=0;
    this.listSecond[Index]=0;
      return;
   }

  }










  public loadHorarios() {
    let fec = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
    //alert(fec);
    // let url2 = 'horario?'
    //   + "fields=idHorario,entradaUpdate,salidaUpdate,entradaUpdateDescription,salidaUpdateDescription,tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
    //   + "&where=H.idEmpleado=" + this.cbl.id_empleado + " and date(H.inicio)='" + fec + "'"
    //   + '&order=inicio';

    let url2 = 'horario?'
      + "fields=idHorario,entradaUpdate,salidaUpdate,entradaUpdateDescription,salidaUpdateDescription,tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i:%25s') as H_entrada,time_format(time((H.fin)),'%25H:%25i:%25s') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i:%25s') as H_saldo"
      + "&where=H.idEmpleado=" + this.cbl.id_empleado + " and date(H.inicio)='" + fec + "'"
      + '&order=inicio';

    return this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then((r) => {
      console.log(r);
      this.fichajes = r['resp'];
      console.log("aaaaaaaaaaaaaaaaa",r['resp']);
      if (this.fichajes.length > 0) {
        if (this.fichajes[this.fichajes.length - 1].salida == null) this.inout = false; else this.inout = true;
        let t = 0;
       for (let f of this.fichajes) {
          //if (f.saldo != null) t += this.horaToMins(f.saldo);
          if (f.saldo != null) t += this.horaToMinsToSec(f.saldo);  
          }
        //this.total = this.minsToHora(t);
        this.total = this.secToHora(t); 
      }

    
    });

  }
  public  timeNow(i) {
    i.value = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }

  public loadEmployee() {
   
    let url2 = 'empleado?'
      + "fields=idEmpleado,idCliente,date_format(fechaAlta,'%25d/%25m/%25Y') as fechaAlta"
      + "&where=idEmpleado=" + this.cbl.id_empleado + " "
      + '&order=idEmpleado';
      

    // return this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then((r) => {
    //   console.log("aaaaaaaaaaaa",r);
    //   console.log("bbbbbbbbbb",r['resp']);
    //   this.employees = r['resp'];
    //   if (this.employees.length > 0) {
       
    //     this.fechaAlta = r['resp'][0].fechaAlta;
        
    //   }
    // });

  






  }



  public WorkedToday() {
        let fec = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
    let url2 = 'horario?'
      + "fields=idHorario,entradaUpdate,salidaUpdate,entradaUpdateDescription,salidaUpdateDescription,tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
      + "&where=H.idEmpleado=" + this.cbl.id_empleado + " and date(H.inicio)='" + fec + "'"
      + '&order=inicio';

    return this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
      console.log(r);
      this.totalWoredTimes = r['resp'];
      this.totalWoredTime='';
      console.log("aaaaaaaaaaaaaaaaa",r['resp']);
      if (this.totalWoredTimes.length > 0) {
        // if (this.totalWoredTimes[this.totalWoredTimes.length - 1].salida == null) this.inout = false; else this.inout = true;
        let w = 0;
        for (let k of this.totalWoredTimes) {

        //   if (f.saldo != null) t += this.horaToMins(f.saldo);
        //   }
        // this.total = this.minsToHora(t);

        console.log(k.saldo+"---"+k.entrada+"---"+formatDate(Date.now(), 'H:mm', 'en-US'));
          //if(k.saldo != null)
          if(k.saldo != null || k.saldo != '')
          {
            console.log("dokop");
            //w += k.entrada+"-"+k.salida+",";
            //let diff=this.horaToMins(k.salida)-this.horaToMins(k.entrada);
            w += this.horaToMins(k.salida)-this.horaToMins(k.entrada);
          }
          else{
            //let currentTime=this.timeNow(new Date());
            let currentTime = formatDate(Date.now(), 'H:mm', 'en-US');
            // w += k.entrada+"-"+currentTime+","; 
            w +=  this.horaToMins(currentTime)-this.horaToMins(k.entrada);
          }










        }
        
        // this.hours = timer(0, 1000).subscribe((r) => {
        //   this.crono = new Date();
        //   this.totalWoredTime = w;
        // });
        this.totalWoredTime = this.minsToHora(w);
        
      }

      



    });

  }




  async getProjectEmployeeHorario(field,idClient_project_employee,idEmpleado,idhorario){
    let url2='client_project_employee_horario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idClient_project_employee_horario,idClient_project_employee,idEmpleado,idhorario,	hour,minute,second,dateTime,work_desc'
    +'&where=idClient_project_employee='+idClient_project_employee+' and idEmpleado='+idEmpleado+'  and idhorario='+idhorario+' '
    +'&order=idClient_project_employee_horario DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];

      if(resp.length==0)
      {
        respuesta='';
      }
      else{
        
        console.log("sssssssssssssssssspppppppppp",resp);
        //respuesta=resp[0]['project_name'];
         respuesta=resp[0][field];
      }
      
      
      //this.nominasTemp = r['resp'];
      console.log(field+"=============.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }




  async getProjectName(id){
    let url2='client_project?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idClient_project,clientId,project_name'
    +'&where=idClient_project='+id+' '
    +'&order=idClient_project DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['project_name'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }


  public clientProjectEmployee() {
   
let url2 = 'client_project_employee?'
  + "fields=idClient_project_employee,clientId,idClient_project,idEmpleado"
  + "&where=idEmpleado=" + this.cbl.id_empleado 
  + '&order=idClient_project_employee';



// let url2='client_project_employee ?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
//     // +'&joins=client_project(client_project_employee.idClient_project=>client_project.idClient_project)'
//     +'&fields=client_project_employee.idClient_project_employee,client_project_employee.clientId,client_project_employee.idClient_project,client_project_employee.idEmpleado,client_project_employee.hour,client_project_employee.minute,client_project_employee.dateTime,client_project_employee.work_desc'
//     +'&where=client_project_employee.idEmpleado ='+this.cbl.id_empleado +' '
//     +'&order=client_project_employee.idClient_project_employee';  //&compacto=true

// let url2 = 'client_project_employee?'
//   +'&joins=client_project(client_project_employee.idClient_project=>client_project.idClient_project)'
//   + "fields=idClient_project_employee,clientId,idClient_project,idEmpleado,hour,minute,dateTime,work_desc"
//   + "&where=idEmpleado=" + this.cbl.id_empleado 
//   + '&order=idClient_project_employee';


 this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
  console.log(r);
  this.clientProjectEmployees = r['resp'];
  this.clientProjectEmployeeAll='';
  console.log("aaaaaaaaddddddddaaaaaaaaa",r['resp']);
  if (this.clientProjectEmployees.length > 0) {
    // if (this.totalWoredTimes[this.totalWoredTimes.length - 1].salida == null) this.inout = false; else this.inout = true;
    let w = 0;
    
    for (let k of this.clientProjectEmployees) {

      k['projectName']=await this.getProjectName(k.idClient_project);

    //   if (f.saldo != null) t += this.horaToMins(f.saldo);
    //   }
    // this.total = this.minsToHora(t);

    
      // if(k.saldo != null)
      // {
      //   //w += k.entrada+"-"+k.salida+",";
      //   //let diff=this.horaToMins(k.salida)-this.horaToMins(k.entrada);
      //   w += this.horaToMins(k.salida)-this.horaToMins(k.entrada);
      // }
      // else{
      //   //let currentTime=this.timeNow(new Date());
      //   let currentTime = formatDate(Date.now(), 'H:mm', 'en-US');
      //   // w += k.entrada+"-"+currentTime+","; 
      //   w +=  this.horaToMins(currentTime)-this.horaToMins(k.entrada);
      // }


      //this.listidClientProjectEmployee[w]=k.idClient_project_employee;

      //this.listHour[w]=k.hour;
      //this.listMinute[w]=k.minute;

      //this.listWorkDesc[w]=k.work_desc;
      //this.listWorkDesc1[w][w]=k.work_desc;
      

      w++;
    }
    

    console.log("kkkkkkkkkiiiiiiii",this.listWorkDesc1);
   // this.clientProjectEmployeeAll = this.minsToHora(w);
    
  }

  



});

}

public clientProjectEmpHorario() {
  let fec = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
let url2 = 'horario?'
+ "fields=idHorario,entradaUpdate,salidaUpdate,entradaUpdateDescription,salidaUpdateDescription,tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
+ "&where=H.idEmpleado=" + this.cbl.id_empleado + " and date(H.inicio)='" + fec + "'"
+ '&order=inicio';

return this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
console.log(r);
this.totalWoredTimes = r['resp'];
this.totalWoredTime='';
console.log("aaaaaaaaaaaaaaaaa",r['resp']);
if (this.totalWoredTimes.length > 0) {
  // if (this.totalWoredTimes[this.totalWoredTimes.length - 1].salida == null) this.inout = false; else this.inout = true;

  for (let k of this.totalWoredTimes) {


  


    let p = 0;
    for (let m of this.clientProjectEmployees) {

     // k['projectName']=await this.getProjectName(m.idClient_project);
         
         var mainIndex=k.idHorario+'_'+p;

         

         this.listidClient_project_employee[mainIndex]=m.idClient_project_employee;
         this.listidClient_project[mainIndex]=m.idClient_project;
         this.listidEmpleado[mainIndex]=m.idEmpleado;
         this.listidhorario[mainIndex]=k.idHorario;


         //this.listWorkDesc[mainIndex]=await this.getProjectName(1);

         this.listidClient_project_employee_horario[mainIndex]= await this.getProjectEmployeeHorario("idClient_project_employee_horario",m.idClient_project_employee,m.idEmpleado,k.idHorario);

         this.listHour[mainIndex]=await this.getProjectEmployeeHorario("hour",m.idClient_project_employee,m.idEmpleado,k.idHorario);
         this.listMinute[mainIndex]=await this.getProjectEmployeeHorario("minute",m.idClient_project_employee,m.idEmpleado,k.idHorario);
         this.listSecond[mainIndex]=await this.getProjectEmployeeHorario("second",m.idClient_project_employee,m.idEmpleado,k.idHorario);
         this.listWorkDesc[mainIndex]=await this.getProjectEmployeeHorario("work_desc",m.idClient_project_employee,m.idEmpleado,k.idHorario);
         


      p++;
    }





















  }
  

  
}





});

}





public clientProjectEmployeeHorario() {
   
  let url2 = 'client_project_employee?'
    + "fields=idClient_project_employee,clientId,idClient_project,idEmpleado"
    + "&where=idEmpleado=" + this.cbl.id_empleado 
    + '&order=idClient_project_employee';
  
  
  
  // let url2='client_project_employee ?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //     // +'&joins=client_project(client_project_employee.idClient_project=>client_project.idClient_project)'
  //     +'&fields=client_project_employee.idClient_project_employee,client_project_employee.clientId,client_project_employee.idClient_project,client_project_employee.idEmpleado,client_project_employee.hour,client_project_employee.minute,client_project_employee.dateTime,client_project_employee.work_desc'
  //     +'&where=client_project_employee.idEmpleado ='+this.cbl.id_empleado +' '
  //     +'&order=client_project_employee.idClient_project_employee';  //&compacto=true
  
  // let url2 = 'client_project_employee?'
  //   +'&joins=client_project(client_project_employee.idClient_project=>client_project.idClient_project)'
  //   + "fields=idClient_project_employee,clientId,idClient_project,idEmpleado,hour,minute,dateTime,work_desc"
  //   + "&where=idEmpleado=" + this.cbl.id_empleado 
  //   + '&order=idClient_project_employee';
  
  
   this.httpClient.get(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
    console.log(r);
    this.clientProjectEmployees = r['resp'];
    this.clientProjectEmployeeAll='';
    console.log("aaaaaaaaddddddddaaaaaaaaa",r['resp']);
    if (this.clientProjectEmployees.length > 0) {
      // if (this.totalWoredTimes[this.totalWoredTimes.length - 1].salida == null) this.inout = false; else this.inout = true;
      let w = 0;
      
      for (let k of this.clientProjectEmployees) {
  
        k['projectName']=await this.getProjectName(k.idClient_project);
  
      //   if (f.saldo != null) t += this.horaToMins(f.saldo);
      //   }
      // this.total = this.minsToHora(t);
  
      
        // if(k.saldo != null)
        // {
        //   //w += k.entrada+"-"+k.salida+",";
        //   //let diff=this.horaToMins(k.salida)-this.horaToMins(k.entrada);
        //   w += this.horaToMins(k.salida)-this.horaToMins(k.entrada);
        // }
        // else{
        //   //let currentTime=this.timeNow(new Date());
        //   let currentTime = formatDate(Date.now(), 'H:mm', 'en-US');
        //   // w += k.entrada+"-"+currentTime+","; 
        //   w +=  this.horaToMins(currentTime)-this.horaToMins(k.entrada);
        // }
  
  
        //this.listidClientProjectEmployee[w]=k.idClient_project_employee;
  
        //this.listHour[w]=k.hour;
        //this.listMinute[w]=k.minute;
  
        //this.listWorkDesc[w]=k.work_desc;
        //this.listWorkDesc1[w][w]=k.work_desc;
        
  
        w++;
      }
      
  
      console.log("kkkkkkkkkiiiiiiii",this.listWorkDesc1);
     // this.clientProjectEmployeeAll = this.minsToHora(w);
      
    }
  
    
  
  
  
  });
  
  }



  enterMissedLogInLogOut(data ,idHorario) {
    this.missLoginTimePopup = data == 'updateEntrada' ? true : false;
    this.displayAsunto = true;
    this.idHorario=idHorario;
    this.formInit()
  }

  formInit() {
    let date = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US')
    this.myForm = this.fb.group({
      time: ['', Validators.required],
      date: [date, [Validators.required]],
      description: ['', [Validators.required, Validators.minLength(5)]],
    });
  }
 async onSubmit(form: FormGroup) {
    this.displayAsunto = false;
    console.log('Valid?', form.valid); // true or false
    this.missLoginTimePopup ? (this.type="entry") : (this.type="exit");
    //this.missLoginTimePopup ? (this.updateEntrada = form.value.time,this.type="entry") : (this.updateSalida = form.value.time,this.type="exit");
    let time=form.value.date+" "+form.value.time
    let {description}=form.value;
    let data={time,description};
   let empleadoId= this.cbl.id_empleado;
   console.log("this.fichajes...........................",this.fichajes)
   this.updateEmployeeMisspunch(data,this.type,this.idHorario);
  
 // for (let mispunch of  this.fichajes){
   // if((mispunch.entradaUpdate==null && this.type=='entry'&& mispunch.entrada!=null) ||(this.type=='exit' && mispunch.salidaUpdate==null&& mispunch.salida!=null)){
    //  this.updateEmployeeMisspunch(data,this.type,mispunch.idHorario);
     // return;
   // }
  //}
  }
  updateEmployeeMisspunch(data,type,idhorario){
    type dataType =  {data:{time:string,type:string,date:string,description:string}}
    this.emleadoService.updateEmployeeMisspunch(data,type,idhorario).subscribe((res:dataType)=>{
     console.log("res",res,res.data.type)
     this.loadHorarios();
     this.clearForm();
    })
  }

  async enterProjectEmployee(data,idHorario,count,rowIndex) {
    
    // if (this.projectemployee.hour == "") {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Incidencia es obligatorio!' });
    //   return;
    // }

   
    //alert("sssss");
    //alert(idHorario+"_"+count+"_"+rowIndex);
 
    console.log(this.listHour.length);
    console.log("bbbbbbbbbbbbbbbbbbbb",this.listHour);
    //console.log(this.listWorkDesc1);
    // console.log(this.listidClientProjectEmployee);
  
    // console.log(this.listMinute);
    // console.log(this.listWorkDesc);
    // console.log(this.clientProjectEmployees.length);
    
    // var listidClientProjectEmployeeItem = this.listidClientProjectEmployee.join('@@@');
    // var listHourItem = this.listHour.join('@@@');
    // var listMinuteItem = this.listMinute.join('@@@');
    // var listlistWorkDesc = this.listWorkDesc.join('@@@');

    //alert(listidClientProjectEmployeeItem+"--"+listHourItem+'--'+listMinuteItem+'--'+listlistWorkDesc);
    var listidClient_project_employee_horarioArr=[];
    var listidClient_project_employeeArr=[];
    var listidClient_projectArr=[];
    var listidEmpleadoArr=[];
    var listidhorarioArr=[];

    var listHourArr=[];
    var listMinuteArr=[];
    var listSecondArr=[];
    var listWorkDescArr=[];

    for(var x=0;x<count;x++)
    {
      var mainIndex=idHorario+"_"+x;

      listidClient_project_employee_horarioArr[x]=this.listidClient_project_employee_horario[mainIndex];
      listidClient_project_employeeArr[x]=this.listidClient_project_employee[mainIndex];
      listidClient_projectArr[x]=this.listidClient_project[mainIndex];
      listidEmpleadoArr[x]=this.listidEmpleado[mainIndex];
      listidhorarioArr[x]=this.listidhorario[mainIndex];
      listHourArr[x]=this.listHour[mainIndex];
      listMinuteArr[x]=this.listMinute[mainIndex];
      listSecondArr[x]=this.listSecond[mainIndex];
      listWorkDescArr[x]=this.listWorkDesc[mainIndex];
     

    }

    console.log("xxxxxxxxxxxxxxx",listHourArr);


var listidClient_project_employee_horarioItem = listidClient_project_employee_horarioArr.join('@@@');
var listidClient_project_employeeItem = listidClient_project_employeeArr.join('@@@');
var listidClient_projectItem = listidClient_projectArr.join('@@@');
var listidEmpleadoItem = listidEmpleadoArr.join('@@@');
var listidhorarioItem = listidhorarioArr.join('@@@');
var listHourItem = listHourArr.join('@@@');
var listMinuteItem = listMinuteArr.join('@@@');
var listSecondItem = listSecondArr.join('@@@');
var listWorkDescItem = listWorkDescArr.join('@@@');    

    this.httpClient.post(this.cbl.urlBaseBL + 'client_project_employee_work/' , {
      idClient_project_employee_horarioItem:listidClient_project_employee_horarioItem,
      idClient_project_employeeItem:listidClient_project_employeeItem,
      idClient_projectItem:listidClient_projectItem,
      idEmpleadoItem:listidEmpleadoItem,
      idhorarioItem:listidhorarioItem,
      HourItem:listHourItem,
      MinuteItem:listMinuteItem,
      SecondItem:listSecondItem,
      WorkDescItem:listWorkDescItem,
      dateTime:formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US')
    }).toPromise().then((p) => {
      if (p) {
        //////////////

        //alert('Successfully updated!');
        this.messageService.add({severity: 'info',summary: 'Completado!',detail: 'Datos actualizados!'});
        
       //alert('Successfully updated!');





      }
      });

    


  }
  clearForm() {
    this.myForm.reset({
      'time': '',
      'description': '',
    });
  }
 
}

import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router,ActivatedRoute } from '@angular/router';
import { EmpleadoService } from '../servicios/empleado.service';
import { CBLService } from '../servicios/cbl.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  subEmployees: any = [];

  navbar: MenuItem[];
  usersMenu: MenuItem[];
  sidenavMenu: MenuItem[];
  footerMenu: MenuItem[];
  mySelect: any = "choose";
  employeedata: any;

  ///////////By poulami start/////////
  public show:boolean = false;
  public buttonName:any = 'Show';
  public menus:any;
  public angularProjectVersion:string="";
 ///////////By poulami end /////////


  constructor(private router: Router,private actrouter: ActivatedRoute,private cblService:CBLService) {}    

  ngOnInit(): void {

    this.actrouter.queryParams.subscribe(params => {
      console.log(params);
    });
    this.cblService.subsubEmployeeObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.subEmployees = data;
  });
    this.usersMenu = [
      {
        label: 'Cambiar contraseña',
        icon: 'fas fa-user-lock',
        routerLink: ['/index/changepassword'],
      },
      {
        label: 'Cerrar sesión',
        icon: 'fas fa-sign-out-alt',
        routerLink: ['/login'],
        command: () => {
          this.logout();
        },
      },
    ];


    let angularProjectVersion =this.cblService.angularProjectVersion;
    console.log("ddddddddddddddd",angularProjectVersion);
    this.angularProjectVersion =""+angularProjectVersion+"";
   // alert(angularProjectVersion);


    this.sidenavMenu = [
      {
        label: 'Jornadas', 
        icon: 'fas fa-clock',
        routerLink: ['/index/jornadas/fichar'],
      },
      {
        label: 'Incidencias',
        icon: 'fas fa-exclamation-triangle',
        routerLink: ['/index/incidencias'],
      },
      {
        label: 'Nóminas',
        icon: 'fas fa-euro-sign',
        routerLink: ['/index/nominas'], 
      },
      {
        label: 'Documentos',
        icon: 'fas fa-briefcase',
        routerLink: ['/index/docs'],
      },
      {
        label: 'Permisos',
        icon: 'fas fa-umbrella-beach',
        routerLink: ['/index/permisos'],
      },
      {
        label: 'Comunicaciones',
        icon: 'fas fa-envelope-open',
        routerLink: ['/index/comunicacion'],
      },
      {
        label: 'Cambiar contraseña',
        icon: 'fas fa-user-lock',
        routerLink: ['/index/changepassword'],
      },
      {
        label: 'Portal de denuncias',
        icon: 'fas fa-book',
        routerLink: ['/index/complaints'],
      }
    ];
 
    this.navbar = [
      {
        label: 'Portal del Empleado',
        icon: 'fas fa-user',
        routerLink: ['/index'],
      },
    ];

    this.footerMenu = [
      {
        label: 'Permisos',
        icon: 'fas fa-umbrella-beach',
        routerLink: ['/index/permisos'],
      },
      {
        label: 'Documentos',
        icon: 'fas fa-briefcase',
        routerLink: ['/index/docs'],
      },
      {
        label: 'Incidencias',
        icon: 'fas fa-exclamation-triangle',
        routerLink: ['/index/incidencias'],
      },
      {
        label: 'Jornadas',
        icon: 'fas fa-clock',
        routerLink: ['/index/jornadas'],
      },
      {
        label: 'Nóminas',
        icon: 'fas fa-euro-sign',
        routerLink: ['/index/nominas'],
      },
      {
        label: 'Comunicaciones',
        icon: 'fas fa-envelope-open',
        routerLink: ['/index/permisos'],
      },
      {
        label: 'Complaint',
        icon: 'fas fa-book',
        routerLink: ['/index/complaints'],
      }
    ];



      ///////////By poulami start/////////
      this.menus = [
        { id: 1, name: 'Jornadas', href: '#/index/jornadas/fichar'},
        { id: 2, name: 'Incidencias', href: '#/index/incidencias' },
        { id: 3, name: 'Nóminas', href: '#/index/nominas' },
        { id: 4, name: 'Documentos', href: '#/index/docs' },
        { id: 5, name: 'Permisos', href: '#/index/permisos' },
        { id: 6, name: 'Comunicaciones', href: '#/index/comunicacion' },
        { id: 7, name: 'Cambiar contraseña', href: '#/index/changepassword' },
        { id: 8, name: 'Complaint', href: '#/index/complaints' }
       
      ];;
 ///////////By poulami end /////////
  }

  logout(): void {}

  activeMenu(event): void {
    let node;
    if (event.target.tagName === 'A') {
      node = event.target;
    } else {
      node = event.target.parentNode;
    }
    let menuitem = document.getElementsByClassName('ui-menuitem-link');
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove('active');
    }
    node.classList.add('active');
  }

  perfil(){
    this.router.navigate(['/index/perfil']);
  }
  selectChange() {
    this.employeedata = this.subEmployees.filter(id => id.idEmpleado == this.mySelect);
}
  updateUrl() {
    let { nif, apikey,idEmpleado } = this.employeedata[0];
    let url = window.location.href.split("#")[0];
    // ***************************
    // LOCAL PATH 
    // ***************************
    //let navigation = `${url}empleados/#/login/user/${nif}/apikey/${apikey}/${idEmpleado}`
    // ***************************
    // LIVE PATH 
    // ***************************
    let navigation = `${url}#/login/user/${nif}/apikey/${apikey}/${idEmpleado}`
    // remote access
    // let navigation = `${url}#/login/user/${nif}/apikey/${apikey}`
    window.location.replace(navigation);
 }

  ///////////By poulami start/////////
  toggle() {
    this.show = !this.show;
  
    // Change the name of the button.
    if(this.show)  
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }
  
  myFunction() {
    var x = document.getElementById("myTopnav");
   // alert(x.className);
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  
 ///////////By poulami end /////////


}

<div class="container">
    <header>
        <p-menubar [model]="navbar">
            <div>
               <!-- <button pButton icon="fas fa-envelope" style="margin-right:.25em"
                    [routerLink]="['/index/notificaciones']"> <span class="badge">3</span></button>-->
                    <div class="ng-header-right-item right" style="margin-top: 14px;display: inline-block;">
                        <select  [(ngModel)]="mySelect" (change)="selectChange()">
                            <option>Seleccionar</option>
                            <option [value]="employee.idEmpleado" *ngFor="let employee of subEmployees">
                                {{employee.Cliente}}
                            </option>
                        </select>
                        <span><button (click)="updateUrl()">Cambiar de cuenta </button></span>
                    </div>
                <p-splitButton icon="fas fa-user-circle" class="greybtn" [model]="usersMenu" id="close-menu" (onClick)="perfil()">
                </p-splitButton>
            </div>
        </p-menubar>


        <!-- ///////////By poulami start///////// -->

        <div class="topnav" id="myTopnav">
            <a href="#/index" class="active"> 
                <span class="ui-menuitem-icon fas fa-user ng-star-inserted" ng-reflect-ng-class="fas fa-user"></span>
                Portal del Empleado
            </a>
            <!-- <a href="#/index/jornadas/fichar" (click)="myFunction()">Jornadas</a>
            <a href="#/index/incidencias" (click)="myFunction()">Incidencias</a>
            <a href="#/index/nominas" (click)="myFunction()">Nóminas</a>
            <a href="#/index/docs" (click)="myFunction()">Documentos</a>
            <a href="#/index/permisos" (click)="myFunction()">Permisos</a>
            <a href="#/index/comunicacion" (click)="myFunction()">Comunicaciones</a>
            <a href="#/index/changepassword" (click)="myFunction()">Cambiar contraseña</a> -->

           
            <a *ngFor="let menu of menus" href="{{menu.href}}" (click)="myFunction()" id="{{menu.id}}" >{{menu.name}} </a>

            <a href="javascript:void(0);" class="icon" (click)="myFunction()">
              <i class="fa fa-bars"></i>
            </a>
          </div>

          <!-- ///////////By poulami end ///////// -->





    </header>

    <nav>
        <p-menu [model]="sidenavMenu" id="side" (click)="activeMenu($event)"></p-menu>
    </nav>

    <main>
        <router-outlet></router-outlet>
    </main>

    <!-- <footer>
        <p-menubar [model]="footerMenu"></p-menubar>
    </footer> -->

    
        <div class="ui-menubar-custom"  style="width: 700%;" >

            
                
                        <div class="ng-header-right-item right" style="margin: 10px; float: right;
                        margin-right: 50px;">
                           
                            <span>{{this.angularProjectVersion}}</span>
                        </div>
                    
                
           
            
            
       </div>
  

   
</div>
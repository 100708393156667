
<div class="header-nominas">
   
    <div class="center nominas_width" >
        <h3 class="title">Nóminas</h3>  
    </div>
    <div class="right">
        <div style=" display:flex;  flex-direction: row;">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
            >
            </p-dropdown>
            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div>
</div>


<div class="header-nominas">
    <div class="left">
       <h3 class="message-for-allignment" > {{message}} {{currentSearchYear}} </h3>
    </div>
    

</div>


<p-scrollPanel [style]="{width: '100%'}">
    <div class="example-column">
        <p-table (click)="click($event)" [value]="nominas" sortMode="multiple" [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="fecha">Nómina<p-sortIcon field="fecha"></p-sortIcon>
                    </th>
                    <th>Importe</th>
                    <th>Documentos</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-nomina>
                <tr>
                    <td>{{nomina.fecha | MonthStr}}</td>
                    <td>{{nomina.importe | currency:'EUR'}}</td>
                    <td>
                        <i [attr.data-id]="doc" class="far fa-file-pdf icono enlace" *ngFor="let doc of nomina.docs"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
            <br>
            <br>
            <br>
            <br>
    </div>
</p-scrollPanel>
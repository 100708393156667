import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolicitudInterceptor } from './solicitud.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Utilidades } from '../libs/utilidades';
import { Respuesta } from '../libs/respuesta';
import { spanishDateConfig } from '../libs/SpanishDate'
import { formatDate } from '@angular/common';
import { Visita, Solicitud, Complaint } from '../libs/dataObjects'
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CBLService {

	public urlBase: String;//="http://localhost:3000/";
	//public urlBase:String="https://portalasesoria.oficinadigital.eu/";

	public angularProjectVersion: String;//=this.urlBase+"apidl/";
	public urlBaseDL: String;//=this.urlBase+"apidl/";
	public urlBaseBL: String;//=this.urlBase+"apibl/";
	//public urlBaseDL:String="https://oficinadigital.eu:8443/serviceODE/";
	//public urlBaseDL:String="https://extranet.oficinadigital.eu/serviceODE/";
	private p_users: Promise<Object[]> = null;
	private p_materias: Promise<Object[]> = null;
	private cached_materias: Object[] = null;
	private p_clientes: Promise<Object[]> = null;
	private p_ejercicios: Promise<Object[]> = null;
	private p_login: Promise<String> = null;

	public id_cliente: number = 0;
	public name_cliente: string = '';
	public id_empleado: number = 0;
	public pwd_cliente: string = "";
	public empleado: string = "";

	public ejercicios = [];
	public ejercicio_actual: number;
	public materias = [];
	public subMaterias = [];
	public meses: {} = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((mes, i) => {
		if (i < 9) return { label: mes, value: '0' + (i + 1) }; else return { label: mes, value: '' + (i + 1) };
	});
	public mes_actual: string;
	private empleados: [];
	public dateConfig = spanishDateConfig;
	public subEmployee = new BehaviorSubject([]);
	subsubEmployeeObservable = this.subEmployee.asObservable();

	hideLeftemployeeEntryTime = new BehaviorSubject({});
	hideLeftemployeeObservable = this.hideLeftemployeeEntryTime.asObservable();

	constructor(private httpClient: HttpClient) {
		//console.log(environment.production);
		//console.log(environment.urlBase);
		this.angularProjectVersion = "Versión 03.09.2024";
		this.urlBase = environment.urlBase;
		this.urlBaseDL = this.urlBase + "apidl/";
		this.urlBaseBL = this.urlBase + "apibl/";
		this.mes_actual = '' + ((new Date()).getMonth() + 1);
		if (this.mes_actual.length == 1) this.mes_actual = '0' + this.mes_actual;
	}

	preloginEmpleado(code: string): Promise<Respuesta> {
		return this.httpClient.post<Respuesta>(this.urlBaseDL + 'empleados/prelogin', { code: code }).toPromise();
	}

	// ===========================================================
	//                       Metodos API Rest
	// ===========================================================
	_get_users(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'usuarios').toPromise();
	}

	_get_materias(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'materias?fields=[idPadre,idMateria,materia]').toPromise();
	}

	_get_ejercicios(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'documentos/ejercicios').toPromise();
	}

	_get_clientes(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'clientes').toPromise();
	}

	init() {
		this.httpClient.post(this.urlBaseDL + 'multiple',
			[
				{
					"method": "get", "url": "materias", "params": {
						"order": "materia",
						"fields": '[idPadre,idMateria,materia]',
						"where": "idMateria!=200"
					}
				},

				{
					"method": "get", "url": "ejercicios", "params": {
						"order": "ejercicio DESC",
						"fields": '[ejercicio]'
					}
				},

				{
					"method": "get", "url": "empleado", "params": {
						order: "T.empleado",
						joins: "trabajador",
						fields: '[E.idEmpleado,T.empleado]',
						where: "E.baja=0 and E.idCliente=" + this.id_cliente
					}
				}
			]
		).subscribe((res) => {
			console.log(res);

			this.subMaterias = res[0]['resp'];
			this.materias = this.subMaterias.filter(mat => mat[0] === 0).map(mat => {
				return { label: mat[2], value: mat[1] };
			});

			this.ejercicios = res[1]['resp'].map(ej => { return { label: String(ej), value: ej } });
			this.ejercicio_actual = res[1]['resp'][0];

			this.empleados = res[2]['resp'].map((emp) => { return { value: emp[0], label: emp[1] } });

		});
	}

	_login(user: number, pass: string): Promise<Object> {
		const payload = new HttpParams()
			.set('username', String(user))
			.set('password', pass);

		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login', payload).toPromise();
	}

	_login2(user: number, pass: string): Promise<Object> {
		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login2',
			{
				username: user,
				password: pass
			}).toPromise();
	}

	_login3(user: number, pass: string): Promise<Object> {
		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login3',
			{
				username: user,
				password: pass
			}).toPromise();
	}

	async makeLoginSync(user: number, pass: string): Promise<Object> {
		const obj = <Object>await this._login2(user, pass);
		return obj;
	}

	async loginEmpleado(code: string, pass: string,empId:any=null): Promise<Boolean> {
		var out: Boolean = false;

		const payload = new HttpParams()
			.set('code', code)
			.set('pwd', pass)
			.set('empId', empId);

		await this.httpClient.post<Object>(this.urlBaseDL + 'empleados/login', payload).toPromise().then((res) => {
			res = res['resp'];
			console.log("empleados/login/rrrrrrrrrrrrr",res);
			if (res['token'].length > 0) {
				console.log("LOGADO");
				this.id_cliente = res['idCliente'];
				this.id_empleado = res['idEmpleado'];
				this.name_cliente = res['name_cliente'];
				this.empleado = res['empleado'];
				this.pwd_cliente = pass;
				this.subEmployee.next(res['subEmpleados'])
				//SolicitudInterceptor.token=res['token'];
				//this.hideLeftemployeeEntryTime.next({fechaVencimiento:res["fechaVencimiento"],baja:res['baja']})
				let allowLogin = this.employeeSubscriptionCheck(res["fechaVencimiento"], res['baja']);
				if (allowLogin) {
					SolicitudInterceptor.token = res['token'];
				}
				out = true;
			}
			else {
				out = false;
			}
		});

		return out;

	}

	async logoutCliente() {

		return this.httpClient.get(this.urlBaseDL + 'logout', { responseType: 'text' }).subscribe((res) => {
			console.log(res);
			this.id_cliente = 0;
			this.pwd_cliente = "";
			SolicitudInterceptor.token = '';
		});

		/* NO BORRAR - ES UN EJEMPLO DE POST CON RESPUESTA TEXT */
		/*return this.httpClient.post(this.urlBaseDL+'logout', null,{responseType: 'text'}).subscribe((res)=>{
			console.log(res);
			this.id_cliente=0;
			this.pwd_cliente="";
			SolicitudInterceptor.token='';
		});*/
	}


	// ===========================================================
	//                       Metodos CACHEADOS
	// ===========================================================
	getEmpleados() {
		return this.empleados;
	}


	get_users() {
		if (!this.p_users) this.p_users = this._get_users();
		return this.p_users;
	}

	get_materias() {
		if (!this.p_materias) this.p_materias = this._get_materias();
		return this.p_materias; //.catch(res=>{console.log(res.status);});
	}

	/*async get_sync_materias()
	{
		if(this.cached_materias==null)
		{
			this.cached_materias=await this._get_materias();
			//promesa.then((res)=>{this.cached_materias=res;});
		}
		return this.cached_materias;
	}*/

	get_clientes() {
		if (!this.p_clientes) this.p_clientes = this._get_clientes();
		return this.p_clientes;
	}

	get_ejercicios() {
		if (!this.p_ejercicios) this.p_ejercicios = this._get_ejercicios();
		return this.p_ejercicios;
	}

	// =================================================
	// 		EJEMPLOS DE RECEPCION DE DATOS!!!!!!!!
	// =================================================

	/*
	
	_login3():Promise<Datalogin>   //Convertido a Promise<Datalogin> directamente.
	{
		return this.httpClient.post<Datalogin>('http://localhost:8080/serviceODE/usuarios/prueba',
		{
			username: 100, 
			password: 'Java Functional Interface'
		}).toPromise();
	}
	
	let dl:Datalogin=new Datalogin();
		dl.username="Pepe";
		dl.password="12345";	
		this.httpClient.post('http://localhost:8080/serviceExtranet/usuarios/login',dl).subscribe((res)=>{
		   console.log(res);
		   //console.log(res.token);
		   console.log(res['token']);
		});
	*/

	public getID(ev) {
		var id = 0;
		if (ev.target.hasAttribute("data-id"))
			id = ev.target.attributes['data-id'].value;
		else
			if (ev.target.parentElement.hasAttribute("data-id"))
				id = ev.target.parentElement.attributes['data-id'].value;
			else
				id = 0;
		return id;
	}

	public viewIDDoc(id) {
		console.log(id);
		console.log("we are in the class to see the DOC - viewIDDoc ");
		if (id > 0) {
			//window.open(this.urlBaseDL+'files/'+id+'?ta='+SolicitudInterceptor.token);
			var params = {
				id: id,
				ta: SolicitudInterceptor.token
			};
			window.open(this.urlBaseDL + 'files/' + Utilidades.packVLO(params));
		}
		return false;
	}

	public viewDoc(ev) {
		console.log(ev);
		console.log("we are in the class to see the DOC - viewDoc");
		var id = this.getID(ev);
		this.viewIDDoc(id);
		return false;
	}

	public viewTreeDoc(event) {
		console.log(event.node);
		if (!('children' in event.node)) {
			this.viewIDDoc(event.node.key);
		}
		return false;
	}

	async nuevofichaje(fichaje) {
		await this.httpClient.post<Respuesta>(this.urlBaseDL + 'horario', fichaje).toPromise().then((r) => {
			fichaje['idHorario'] = r.resp.idHorario;
		});
		return fichaje;
	}

	updatefichaje(id, fichaje) {
		return this.httpClient.put<Respuesta>(this.urlBaseDL + 'horario/' + id, fichaje).toPromise();
	}

	insertVisita(fichaje) {
		let f: Object = new Visita(fichaje);
		f['inicio'] = formatDate(fichaje.inicio, 'yyyy-MM-dd H:mm', 'en-US');
		if (fichaje.fin != null)
			f['fin'] = formatDate(fichaje.fin, 'yyyy-MM-dd H:mm', 'en-US');
		return this.httpClient.post<Respuesta>(this.urlBaseDL + 'horario', f).toPromise();
	}

	updateVisita(id, fichaje) {
		console.log(fichaje)
		let f: Object = new Visita(fichaje);
		f['inicio'] = formatDate(fichaje.inicio, 'yyyy-MM-dd H:mm', 'en-US');
		if (fichaje.fin != null)
			f['fin'] = formatDate(fichaje.fin, 'yyyy-MM-dd H:mm', 'en-US');
		return this.updatefichaje(id, f)
	}


	public loadVisita(id): Promise<Visita> {
		let url2 = 'horario?'
			+ "fields=date_format(H.inicio,'%25d/%25M/%25Y %25H:%25i') as H_inicio,date_format(H.fin,'%25d/%25M/%25Y %25H:%25i') as H_fin,detalle,obs,tipo"
			+ '&where=H.idEmpleado=' + this.id_empleado + " and H.idHorario=" + id;

		return this.httpClient.get<Respuesta>(this.urlBaseDL + url2).toPromise().then((r) => {
			return r.resp[0];
		});
	}

	insertSolicitud(sol) {
		console.log(sol); 
		let f: Object = new Solicitud(sol);
		f['fechainicio'] = formatDate(sol.fechainicio, 'yyyy-MM-dd', 'en-US');
		f['fechafin'] = formatDate(sol.fechafin, 'yyyy-MM-dd', 'en-US');
		f['horainicio'] = (sol.horainicio != null) ? formatDate(sol.horainicio, 'H:mm', 'en-US') : null;
		f['horafin'] = (sol.horafin != null) ? formatDate(sol.horafin, 'H:mm', 'en-US') : null;
		if (f['fechas'] == '') f['fechas'] = formatDate(sol.fechainicio, 'dd/MM', 'en-US');
		console.log(f);
		return this.httpClient.post<Respuesta>(this.urlBaseDL + 'solicitudes', f).toPromise().then((r) => {
			sol.idSolicitud = r.resp['idSolicitud'];
			return r.resp;
		});
	}


	insertComplaint(sol) {
		console.log(sol); 
		let f: Object = new Complaint(sol);
		f['complaintDate'] = formatDate(sol.complaintDate, 'yyyy-MM-dd', 'en-US');
		f['currentDate'] = formatDate(sol.currentDate,'yyyy-MM-dd', 'en-US');
		// f['horainicio'] = (sol.horainicio != null) ? formatDate(sol.horainicio, 'H:mm', 'en-US') : null;
		// f['horafin'] = (sol.horafin != null) ? formatDate(sol.horafin, 'H:mm', 'en-US') : null;
		// if (f['fechas'] == '') f['fechas'] = formatDate(sol.fechainicio, 'dd/MM', 'en-US');
		f['idEmpleado'] = sol.idEmpleado;
		f['complaintPrivacy'] = sol.complaintPrivacy;
		f['complaintType'] = sol.complaintType;
		f['complaintDesc'] = sol.complaintDesc;
		f['complaintAttachDocument'] = sol.complaintAttachDocument;
		f['complaintStatus'] = sol.complaintStatus;
		f['complaintCommunication'] = '';
		f['complaintHandledClientId'] = '0';

		console.log(f);
		return this.httpClient.post<Respuesta>(this.urlBaseDL + 'complaint', f).toPromise().then((r) => {
			sol.idSolicitud = r.resp['idComplaint'];
			return r.resp;
		});
	}

	public uploadWithoutProgress(data, uploadURL) {
		return this.httpClient.post<any>(uploadURL, data, {}).toPromise();
	}

	async send_documentos(ficheros, meta: Object, meta_files: Object = {}) {
		const formData = new FormData();
		for (let key in ficheros) {
			console.log("Ficheros:" + key + " ===> " + ficheros[key] + " " + ficheros[key].name);
			formData.append("files", ficheros[key], ficheros[key].name);
		}
		formData.append('meta', JSON.stringify(meta));
		if (Object.keys(meta_files).length > 0) formData.append('meta_files', JSON.stringify(meta_files));
		let results;

		await this.uploadWithoutProgress(formData, this.urlBaseDL + 'files').then((res) => {
			console.log(res);
			results = res.resp.ids;
		});
		return results;
	}

	async send_documentosForpermisos(ficheros, meta: Object, meta_files: Object = {}) {
		const formData = new FormData();;
		formData.append("files", ficheros[0], ficheros[0].name);
		formData.append('meta', JSON.stringify(meta));
		if (Object.keys(meta_files).length > 0) formData.append('meta_files', JSON.stringify(meta_files));
		let results;

		await this.uploadWithoutProgress(formData, this.urlBaseDL + 'files').then((res) => {
			console.log(res);
			results = res.resp.ids;
		});
		return results;
	}

	updatepwd(id, pwd) {
		return this.httpClient.put<Respuesta>(this.urlBaseDL + 'empleado/' + id, { password: pwd }).toPromise();
	}

	emailToCliente(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtocliente/' + id, { email: { subject: subject, body: body } }).toPromise();
	}
 
	emailToDepartment(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtodepartment/' + id, { email: { subject: subject, body: body } }).toPromise();
	}

	emailToDepartmentFromEmployee(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailToDepartmentFromEmployee/' + id, { email: { subject: subject, body: body } }).toPromise();
	}

	emailToDepartmentForLeave(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtodepartmentforleave/' + id, { email: { subject: subject, body: body } }).toPromise(); 
	}

	emailToCollaboratorForLeave(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtocollaboratorforleave/' + id, { email: { subject: subject, body: body } }).toPromise();
	}

	getEmployeeName() {
		return this.empleado;
	}

	getEmployeeId() {
		return this.id_empleado;
	}

	insertorupdateVisitasAttachment(id: any, visitasAttachment: any[]) {
		return this.httpClient.post(this.urlBaseBL + 'horario/uploadVistasAttachment/' + id, visitasAttachment).toPromise();
	}

	getVisitasAttachmentById(id: any) {
		return this.httpClient.get(this.urlBaseBL + 'horario/getVistasAttachmentById/' + id).toPromise();
	}

	updateSolicitud(sol) {
		return this.httpClient.put(this.urlBaseBL + 'solicitud/updateSolicitud', sol).toPromise();
	}

	insertSolicitudAttachment(solicitudeAttachment: any[]) {
		console.log(solicitudeAttachment)
		return this.httpClient.post(this.urlBaseBL + 'solicitud/uploadSolicitudAttachment', solicitudeAttachment).toPromise();
	}

	getSolicitudAttachment(id) {
		return this.httpClient.get(this.urlBaseBL + 'solicitud/getFileNameById/' + id).toPromise();
	}

	viewSolicitudAttachment(filePath) {
		return this.httpClient.post(this.urlBaseBL + 'solicitud/viewFile', { filepath: filePath }).toPromise();
	}

	viewVisitasAttachment(filePath) {
		return this.httpClient.post(this.urlBaseBL + 'visitasAttachment/viewFile', { filepath: filePath }).toPromise();
	}

	updateEmployeeMisspunch() {
		let type = ""
		let data = {}
		return this.httpClient.post(`${this.urlBaseDL}empleado/misspunch/${type}`, data);

	}
	employeeSubscriptionCheck(fechaVencimiento, baja) {
		console.log("fechaVencimiento", fechaVencimiento);
		if (!!fechaVencimiento && baja == 1) {
			console.log("fechaVencimiento1", fechaVencimiento);

			let currentDate = new Date().toISOString().split('T')[0];

			function dateDiffInDays(a, b) {
				const _MS_PER_DAY = 1000 * 60 * 60 * 24;
				// Discard the time and time-zone information.
				const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
				const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

				return Math.floor((+utc2 - +utc1) / _MS_PER_DAY);
			}
		        const a = new Date(fechaVencimiento),
				 //const a = new Date("2023-12-22"),
				b = new Date(currentDate),
				//b = new Date("2022-11-30"),

				difference = dateDiffInDays(a, b);
			console.log("difference before....", difference)

			if((difference<=0)){
			  console.log("difference....",difference);
			  this.hideLeftemployeeEntryTime.next({show:true});
		  return true;
			}
			else if (difference > 0 && difference <= 60) {
				console.log("difference....", difference);
				this.hideLeftemployeeEntryTime.next({ leftoutDays: +difference, showalert: true, show:false })
				return true
			} 
			/*else if (difference > 60) {
				this.hideLeftemployeeEntryTime.next({ show: true });
				return true;
			}*/
			else {
				window.location.reload()
				this.hideLeftemployeeEntryTime.next({ show: false })
				return false
			}
		}
		else {
			this.hideLeftemployeeEntryTime.next({ show: true });
			return true;

		}
	}

	
	/////////////////////////////New  by Poulami///////////////////////////////////////

	updatepassword(id, password) {
		return this.httpClient.put<Respuesta>(this.urlBaseDL + 'empleado/' + id, { password: password }).toPromise();
	}
}
